import React, { Fragment, useState } from "react";
import classes from "./Modal.module.css";
import ReactDOM from "react-dom";

const Overlay = (props) => {
  return <div onClick={props.onClick} className={props.backdrop}></div>;
};

const ModalOverlay = (props) => {
  return (
    <div className={props.modal}>
      <div className={props.content}>{props.children}</div>
    </div>
  );
};

const portal = document.querySelector("#overlays");

const Modal = (props) => {
  const [overlayClass, setOverlayClass] = useState(true);

  const closeOverlay = (e) => {
    console.log(e);
    setOverlayClass(false);
  };

  return (
    <Fragment>
      {ReactDOM.createPortal(
        <Overlay onClick={closeOverlay} backdrop={overlayClass ? classes.backdrop : classes.none}></Overlay>,
        portal
      )}
      {ReactDOM.createPortal(
        <ModalOverlay content={overlayClass ? classes.content : classes.none} modal={overlayClass ? classes.modal : classes.none}>
          {props.children}
        </ModalOverlay>,
        portal
      )}
    </Fragment>
  );
};

export default Modal;

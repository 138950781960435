import React from "react";
import Input from "../../Input/Input";
import { useState, useEffect } from "react";
import classes from "../CNiphtyProphet.module.css";
import ADAprice from "../ADAprice/ADAprice";
import Button from "../../UI/Button";
import ProcessHeader from "../ProcessHeader";

function Lend(props) {
  /* Set the type of the input */

  const initialInterestAda =
    Number(window.localStorage.getItem("lend-interest-ada")) || 0;
  const initialInterestLendDollar =
    Number(window.localStorage.getItem("lend-interest-dollar")) || 0;
  const initialLoanValueLend =
    Number(window.localStorage.getItem("lend-loan-value")) || 0;
  const initialInterestLend =
    Number(window.localStorage.getItem("lend-interest")) || 0;
  const initialLengthLend =
    Number(window.localStorage.getItem("lend-length")) || 0;
  const initialRepayEOL =
    Number(window.localStorage.getItem("lend-repay-eol")) || 0;
  const initialSOL = Number(window.localStorage.getItem("lend-ada-sol")) || 0;
  const initialEOLprice =
    Number(window.localStorage.getItem("lend-ada-eol")) || 0;

  /* Set and define the various states */
  const [loanInterestLendADA, setLoanInterestLendADA] = useState(
    initialInterestAda
  );
  const [loanInterestLendDollar, setLoanInterestLendDollar] = useState(
    initialInterestLendDollar
  );
  const [loanValueLend, setLoanStateLend] = useState(initialLoanValueLend);
  const [interestLend, setInterestLend] = useState(initialInterestLend);
  const [lengthLend, setLengthLend] = useState(initialLengthLend);
  const [loanInterestLendDollarEOL, setLoanInterestLendDollarEOL] = useState(
    initialRepayEOL
  );
  const [ADApriceSOL, setADApriceSOL] = useState(initialSOL);
  const [ADAPriceEOL, setADAPriceEOL] = useState(initialEOLprice);

  const [closeLend, setCloseLend] = useState(false);

  /* Set the length of the loan */
  const SetLoanLength = (e) => {
    setLengthLend(e.target.value);
  };

  /* Set the value of the loan and alter ADA+Interest, ADA+Interest converted to dollar @ current ADA price, and ADA+Interest converted to Dollar @ EOL ADA price */
  const SetLoanValueADA = (e) => {
    setLoanStateLend(e.target.value);
  };

  const SetSOL = (e) => {
    setADApriceSOL(e.target.value);
  };

  /* Set the value of the loan interest and alter ADA+Interest, ADA+Interest converted to dollar @ current ADA price, and ADA+Interest converted to Dollar @ EOL ADA price */
  const SetLoanInterest = (e) => {
    setInterestLend(e.target.value);
  };

  /* Set and define ADA price at EOL and alter the ADA+Interest convert to dollars at EOL ADA price */
  const SetADAPriceEOL = (e) => {
    setADAPriceEOL(e.target.value);
  };

  const ChangeFocusLend = (e) => {
    setTimeout(() => {
      e.target.type = "number";
      e.target.value = "";
    }, 10);
  };

  useEffect(() => {
    setLoanInterestLendADA(
      Number.isInteger((1 + interestLend / 100) * loanValueLend)
        ? (1 + interestLend / 100) * loanValueLend
        : ((1 + interestLend / 100) * loanValueLend).toFixed(2)
    );
    setLoanInterestLendDollar(
      Number.isInteger(parseFloat(loanInterestLendADA * ADApriceSOL))
        ? parseFloat(loanInterestLendADA * ADApriceSOL)
        : parseFloat(loanInterestLendADA * ADApriceSOL).toFixed(2)
    );
    setLoanInterestLendDollarEOL(
      Number.isInteger(parseFloat(ADAPriceEOL * loanInterestLendADA))
        ? parseFloat(ADAPriceEOL * loanInterestLendADA)
        : parseFloat(ADAPriceEOL * loanInterestLendADA).toFixed(2)
    );
  }, [
    interestLend,
    loanValueLend,
    loanInterestLendADA,
    ADApriceSOL,
    ADAPriceEOL,
    loanInterestLendADA,
  ]);

  const SubmitHandler = () => {
    const lendData = [
      { Name: "Loan Value ADA", Data: loanValueLend },
      { Name: "Loan Value w/Interest ADA", Data: loanInterestLendADA },
      {
        Name: "End of Loan Predicted ($) Value",
        Data: loanInterestLendDollarEOL,
      },
    ];
    console.log(lendData);
    props.closeLend(false);
    props.summaryLendData(lendData);
    props.openSummary(true);
  };

  const PreviousHandler = () => {
    props.previous(true);
  };

  return (
    <>
      <div className={props.container}>
        <ProcessHeader blue={props.blue} white={props.white} h2='Lend' />
        <ADAprice></ADAprice>
        <Input
          labelClass={classes.label}
          onFocus={ChangeFocusLend}
          title="Loan Length (Days)"
          onChange={SetLoanLength}
          inputClass={classes.input}
          id="lend-loan-length"
          for="lend-loan-length"
          setp="1"
          value={lengthLend}
          name="lend-loan-length"
        />
        <Input
          labelClass={classes.label}
          onFocus={ChangeFocusLend}
          title="ADA Price Start of Loan"
          onChange={SetSOL}
          step=".01"
          inputClass={classes.input}
          id="lend-loan-length"
          value={ADApriceSOL}
        />
        <Input
          labelClass={classes.label}
          onFocus={ChangeFocusLend}
          title="Loan Value (ADA)"
          onChange={SetLoanValueADA}
          inputClass={classes.input}
          step="1"
          id="lend-loan-value-ada"
          for="lend-loan-value-ada"
          name="lend-loan-value-ada"
          value={loanValueLend}
        />
        <Input
          labelClass={classes.label}
          onFocus={ChangeFocusLend}
          title="Interest %"
          onChange={SetLoanInterest}
          inputClass={classes.input}
          step=".5"
          id="lend-loan-interest"
          for="lend-loan-interest"
          name="lend-loan-interest"
          value={interestLend}
        />
        <Input
          labelClass={classes.label}
          title="Loan w/Int (ADA)"
          readOnly='readonly'
          id="lend-loan-value-winterest-ada"
          type="number"
          for="lend-loan-value-winterest-ada"
          name="lend-loan-value-winterest-ada"
          value={loanInterestLendADA}
        />
        <Input
          labelClass={classes.label}
          title="Current Loan w/Int ($)"
          readOnly='readonly'
          id="lend-loan-value-winterest-dollar"
          type="number"
          for="lend-loan-value-winterest-dollar"
          name="lend-loan-value-winterest-dollar"
          value={loanInterestLendDollar}
        />
        <Input
          labelClass={classes.label}
          onFocus={ChangeFocusLend}
          title="ADA Price($) @ End of Loan"
          onChange={SetADAPriceEOL}
          inputClass={classes.input}
          id="lend-ada-price-eol"
          for="lend-ada-price-eol"
          step=".01"
          name="lend-ada-price-eol"
          value={ADAPriceEOL}
        />
        <Input
          labelClass={classes.label}
          title="Loan w/Int ($) @ End of Loan"
          readOnly='readonly'
          id="lend-loan-value-winterest-dollar-eol"
          type="number"
          for="lend-loan-value-winterest-dollar"
          name="lend-loan-value-winterest-dollar"
          value={loanInterestLendDollarEOL}
        />
      </div>
      {!closeLend && (
        <div className={classes.buttoncontainer}>
          <Button
            title="Previous"
            style={classes.buttonprocess}
            onClick={PreviousHandler}
          />
          <Button
            title="Submit"
            style={classes.buttonprocess}
            onClick={SubmitHandler}
          />
        </div>
      )}
    </>
  );
}

export default Lend;

import React, { useEffect, useState } from "react";
import Input from "../../Input/Input";
import classes from "./Crypto.module.css";
import styles from "../../Dropdown/Dropdown.module.css";
import AdjustProjectList from "../../UI/AdjustProjectList";
import axios from "axios";
import tokenSortList from "../../CryptoProjectData";
import Dropdown from "../../Dropdown/Dropdown";
import DropdownUl from "../../DropdownUl/DropdownUl";
import add from "classnames";
import cniphtyStyle from "../CNiphtyProphet.module.css";
import Button from "../../UI/Button";
import ProcessHeader from "../ProcessHeader";

function Crypto(props) {
  const projectListCrypto = [];
  const projectListUlCrypto = [];

  for (let x of tokenSortList) {
    projectListCrypto.push(
      <Dropdown
        name="cryptodata"
        idDropdown={x.id}
        key={x.id}
        textDropdown={x.crypto}
        valueDropdown={x.coinId}
      ></Dropdown>
    );
  }

  for (let i of tokenSortList) {
    projectListUlCrypto.push(
      <DropdownUl forDropdown={i.id} textDropdown={i.crypto}></DropdownUl>
    );
  }

  const {
    loanValueBorrow,
    loanWInterestBorrow,
    loanValueDollars,
    repayInterestPlusLoan,
    adaEOL,
  } = { ...props.data };

  const initialPurchase =
    Number(window.localStorage.getItem("crypto-purchase")) || 0;
  const initialAmount =
    Number(window.localStorage.getItem("crypto-amount")) || 0;
  const initialInvested =
    Number(window.localStorage.getItem("crypto-invested")) || 0;
  const initialSale = Number(window.localStorage.getItem("crypto-sale")) || 0;
  const initialPNL = Number(window.localStorage.getItem("crypto-pnl")) || 0;
  const initialPNLBorrow =
    Number(window.localStorage.getItem("crypto-pnl-borrow")) || 0;
  const initialFinalPosition =
    Number(window.localStorage.getItem("crypto-final-position")) || 0;
  const initialMaxAmount =
    Number(window.localStorage.getItem("crypto-max-amount")) || 0;
  const initialDifference =
    Number(window.localStorage.getItem("crypto-difference")) || 0;
  const initialNotInvested =
    Number(window.localStorage.getItem("crypto-ada-not-invested")) || 0;

  const [price, setPrice] = useState();
  const [purchasePrice, setPurchasePrice] = useState(initialPurchase);
  const [amountPurchased, setAmountPurchased] = useState(initialAmount);
  const [totalInvested, setTotalInvested] = useState(initialInvested);
  const [salePrice, setSalePrice] = useState(initialSale);
  const [profitLoss, setProfitLoss] = useState(initialPNL);
  const [profitLossBorrow, setProfitLossBorrow] = useState(initialPNLBorrow);
  const [finalPosition, setFinalPosition] = useState(initialFinalPosition);
  const [maxAmount, setMaxAmount] = useState(initialMaxAmount);
  const [difference, setDifference] = useState(initialDifference);
  const [text, setText] = useState("");
  const [adaNotInvested, setAdaNotInvested] = useState(initialNotInvested);

  const ChangeProjectCrypto = (e) => {
    let projectId;
    let selectedToken;
    if (e.length > 1) {
      projectId = e;
      selectedToken = null;
    } else {
      selectedToken = e.target.value;
      projectId = null;
    }

    function sortPolicy() {
      if (projectId !== null) {
        return projectId;
      } else {
        return selectedToken;
      }
    }
    const config = { headers: { Accept: "application/json" } };
    const params = {
      coinId: sortPolicy(),
    };
    axios
      .get(`https://api.coingecko.com/api/v3/coins/${params.coinId}`, config)
      .then((res) => {
        console.log(res);
        setTimeout(() => {
          setText(res.data.name);
          setPrice(res.data.market_data.current_price.usd.toLocaleString());
        }, 500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const changePriceHandler = (e) => {
    setPurchasePrice(e.target.value);
  };

  const changeAmountHandler = (e) => {
    setAmountPurchased(e.target.value);
  };

  const changeSalePriceHandler = (e) => {
    setSalePrice(e.target.value);
  };

  useEffect(() => {
    setTotalInvested(
      Number.isInteger(parseFloat(amountPurchased * purchasePrice))
        ? parseFloat(amountPurchased * purchasePrice)
        : parseFloat(amountPurchased * purchasePrice).toFixed(2)
    );
    setFinalPosition(
      Number.isInteger(salePrice * amountPurchased)
        ? salePrice * amountPurchased
        : (salePrice * amountPurchased).toFixed(2)
    );
    setProfitLoss(
      Number.isInteger(parseFloat(amountPurchased * salePrice - totalInvested))
        ? parseFloat(amountPurchased * salePrice - totalInvested)
        : parseFloat(amountPurchased * salePrice - totalInvested).toFixed(2)
    );
    const max = () =>
      purchasePrice === 0
        ? 0
        : Number.isInteger(parseFloat(loanValueDollars / purchasePrice))
        ? parseFloat(loanValueDollars / purchasePrice)
        : parseFloat(loanValueDollars / purchasePrice).toFixed(2);
    setMaxAmount(max);

    const setZero = () =>
      purchasePrice === 0 && difference === 0
        ? 0
        : Number.isInteger(
            parseFloat(purchasePrice * difference) /
              (loanValueDollars / loanValueBorrow)
          )
        ? parseFloat(
            (purchasePrice * difference) / (loanValueDollars / loanValueBorrow)
          )
        : parseFloat(
            (purchasePrice * difference) / (loanValueDollars / loanValueBorrow)
          ).toFixed(2);

          setAdaNotInvested(setZero)

    setDifference(
      Number.isInteger(parseFloat(maxAmount - amountPurchased))
        ? parseFloat(maxAmount - amountPurchased)
        : parseFloat(maxAmount - amountPurchased).toFixed(2)
    );
  }, [
    salePrice,
    amountPurchased,
    totalInvested,
    purchasePrice,
    finalPosition,
    repayInterestPlusLoan,
    difference,
    loanValueBorrow,
    loanValueDollars,
    adaNotInvested,
    adaEOL,
    repayInterestPlusLoan,
  ]);

  useEffect(() => {
    setProfitLossBorrow(
      Number.isInteger(
        parseFloat(
          finalPosition + adaNotInvested * adaEOL - repayInterestPlusLoan
        )
      )
        ? parseFloat(
            finalPosition + adaNotInvested * adaEOL - repayInterestPlusLoan
          )
        : parseFloat(
            finalPosition + adaNotInvested * adaEOL - repayInterestPlusLoan
          ).toFixed(2)
    );
  }, [adaEOL, finalPosition, adaNotInvested]);

  const ChangeFocus = (e) => {
    setTimeout(() => {
      e.target.type = "number";
      e.target.value = "";
    }, 10);
  };

  useEffect(() => {
    const aave = document.querySelector("#crypto0");
    ChangeProjectCrypto("aave");
    aave.checked = true;
  }, []);

  const SummaryHandler = () => {
    props.closeCrypto(false);
    props.openSummary(true);
    const data = [
      {
        Name: "Crypto Purchased",
        Data: text,
      },
      { Name: "Profit/Loss Borrowed Payment ($)", Data: profitLossBorrow },
      { Name: `Purchase Price of ${text}`, Data: purchasePrice },
      { Name: `Invested ${text} Position`, Data: totalInvested },
      { Name: `Sale Price of ${text}`, Data: salePrice },
      { Name: `Final ${text} Position`, Data: finalPosition },
      { Name: `Profit/Loss ${text} Trade`, Data: profitLoss },
      { Name: "Profit/Loss on Loan", Data: profitLossBorrow },
    ];
    props.cryptoData(data);
  };

  const PreviousHandler = () => {
    props.previous(true);
    props.closeCrypto(false);
  };

  useEffect(() => {
    window.localStorage.setItem("crypto-purchase", purchasePrice);
    window.localStorage.setItem("crypto-amount", amountPurchased);
    window.localStorage.setItem("crypto-invested", totalInvested);
    window.localStorage.setItem("crypto-sale", salePrice);
    window.localStorage.setItem("crypto-pnl", profitLoss);
    window.localStorage.setItem("crypto-pnl-borrow", profitLossBorrow);
    window.localStorage.setItem("crypto-final-position", finalPosition);
    window.localStorage.setItem("crypto-max-amount", maxAmount);
    window.localStorage.setItem("crypto-difference", difference);
    window.localStorage.setItem("crypto-ada-not-invested", adaNotInvested);
  }, [
    purchasePrice,
    amountPurchased,
    totalInvested,
    salePrice,
    profitLoss,
    profitLossBorrow,
    finalPosition,
    maxAmount,
    difference,
    adaNotInvested,
  ]);

  return (
    <>
      <ProcessHeader blue={props.blue} white={props.white} h2="Crypto Trade" />
      <div className={props.container}>
        <AdjustProjectList
          imgClass={styles.img}
          containerClass={add(styles.selectOptionsContainer, classes.width)}
          onClick={ChangeProjectCrypto}
          inputClass={cniphtyStyle.input}
          boxClass={styles.selectOptionsDivBox}
          listOne={projectListCrypto}
          listTwo={projectListUlCrypto}
          iconClass={styles.selectIcon}
          selectListClass={styles.selectList}
          listContainerClass={styles.listContainer}
        />
        <Input readOnly="readonly" title="Coin Price" value={price} />
        <Input
          readOnly="readonly"
          type="number"
          title="Borrowed Amount ADA"
          value={loanValueBorrow}
        />
        <Input
          readOnly="readonly"
          type="number"
          title="Borrowed Amount ($)"
          value={loanValueDollars}
        />
        <Input
          onChange={changePriceHandler}
          onFocus={ChangeFocus}
          inputClass={cniphtyStyle.input}
          title="Purchase Price ($)"
          value={purchasePrice}
        />
        <Input
          title="Max Amount"
          readOnly="readonly"
          type="number"
          value={maxAmount}
        />
        <Input
          title="Amount Purchased"
          value={amountPurchased}
          onFocus={ChangeFocus}
          onChange={changeAmountHandler}
          inputClass={cniphtyStyle.input}
        />
        <Input
          title="Max/Amount Purchased Difference"
          readOnly="readonly"
          type="number"
          value={difference}
        />
        <Input
          title="ADA Not Invested"
          readOnly="readonly"
          value={adaNotInvested}
        />
        <Input
          readOnly="readonly"
          title="Total Invested ($)"
          value={totalInvested}
          type="number"
        />
        <Input
          readOnly="readonly"
          type="number"
          title="Borrowed Payment ADA"
          value={loanWInterestBorrow}
        />
        <Input
          readOnly="readonly"
          type="number"
          title="Borrowed Payment ($)"
          value={repayInterestPlusLoan}
        />
        <Input
          title="Sale Price ($)"
          value={salePrice}
          onFocus={ChangeFocus}
          onChange={changeSalePriceHandler}
          inputClass={cniphtyStyle.input}
        />
        <Input
          readOnly="readonly"
          type="number"
          title="Final Position ($)"
          value={finalPosition}
        />
        <Input
          readOnly="readonly"
          title="Profit/Loss Crypto Trade ($)"
          value={profitLoss}
          type="number"
        />
        <Input
          readOnly="readonly"
          title="Profit/Loss Borrowed Payment ($)"
          value={profitLossBorrow}
          type="number"
        />
      </div>
      <div className={cniphtyStyle.buttoncontainer}>
        <Button
          style={cniphtyStyle.buttonprocess}
          onClick={PreviousHandler}
          title="Previous"
        />
        <Button
          style={cniphtyStyle.buttonprocess}
          onClick={SummaryHandler}
          title="Submit"
        />
      </div>
    </>
  );
}

export default Crypto;

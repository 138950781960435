import React, { useEffect, useState } from "react";
import classes from "./SummaryAnalysis.module.css";
import SummaryAnalysisItem from "./SummaryAnalysisItem";
import Button from "../UI/Button";

const SummaryAnalysis = (props) => {
  const [cnft, setCNFT] = useState(false);
  const [crypto, setCrypto] = useState(false);
  const [borrow, setBorrow] = useState(false);

  const displayDataArr = props.data.map((x) => [x.Name, x.Data]);
  const displayLendArr = displayDataArr.map((x) => (
    <SummaryAnalysisItem label={x[0]} item={x[1]} />
  ));

  const borrowSlice = displayDataArr.slice(0, -12);
  const borrowSliceArr = borrowSlice.map((x) => (
    <SummaryAnalysisItem label={x[0]} item={x[1]} />
  ));

  const cnftSlice = displayDataArr.slice(5, -8);
  const cnftSliceArr = cnftSlice.map((x) => (
    <SummaryAnalysisItem label={x[0]} item={x[1]} />
  ));

  const cryptoSlice = displayDataArr.slice(10);
  const cryptoSliceArr = cryptoSlice.map(x => <SummaryAnalysisItem label={x[0]} item={x[1]} />)

  useEffect(() => {
    console.log(props.data);
    console.log(borrowSlice);
    console.log(borrowSliceArr);
    console.log(displayDataArr);
    console.log(cnftSlice);
  }, [borrowSlice, borrowSliceArr, displayDataArr, cnftSlice]);

  useEffect(() => {
    if (borrowSlice.length > 4) {
      setCNFT(true);
      setBorrow(true);
      setCrypto(true);
    }
  }, [borrowSlice, cnft, crypto]);

  const RestartHandler = () => {
    props.restart(true);
  };

  return (
    <>
      <h2 className={classes.header}>Summary Analysis</h2>
      <div className={classes.div}>
        <div className={classes.summarydiv}>
          <h3 className={classes.h3header}>
            {borrow ? "Borrowing/Shorting ADA" : "Lending ADA"}
          </h3>
          <hr className={classes.hr}></hr>
          {borrow ? borrowSliceArr : displayLendArr}
        </div>
        {cnft && (
          <div className={classes.summarydiv}>
            <h3 className={classes.h3header}>NFT Trade</h3>
            <hr className={classes.hr}></hr>
            {cnftSliceArr}
          </div>
        )}
        {crypto && (
          <div className={classes.summarydiv}>
            <h3 className={classes.h3header}>Crypto Trade</h3>
            <hr className={classes.hr}></hr>
            {cryptoSliceArr}
          </div>
        )}
      </div>
      <div className={classes.buttoncontainer}>
        <Button
          style={classes.button}
          onClick={RestartHandler}
          title="Start Over"
        />
      </div>
    </>
  );
};

export default SummaryAnalysis;

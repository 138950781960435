import classNames from "classnames";
import classes from './ProcessHeader.module.css'
import React from "react";

const ProcessHeader = (props) => {
  return (
    <div className={classes.headerdiv}>
      <h2 className={classes.h2}>{props.h2}</h2>
      <h4 className={classes.h4}>{props.blue}</h4>
      <h4 className={classes.h4}>{props.white}</h4>
    </div>
  );
};

export default ProcessHeader;

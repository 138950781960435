import React from "react";
import { useState, useEffect } from "react";
import Input from "../../Input/Input";
import ADAprice from "../ADAprice/ADAprice";
import classes from "../CNiphtyProphet.module.css";
import Button from "../../UI/Button";
import ProcessHeader from "../ProcessHeader";

function Borrow(props) {
  const initialLength = () =>
    Number(window.localStorage.getItem("borrow-length")) || 0;
  const initialSOL = () =>
    Number(window.localStorage.getItem("borrow-sol")) || 0;
  const initialEOL = () =>
    Number(window.localStorage.getItem("borrow-eol")) || 0;
  const initialInterest = () =>
    Number(window.localStorage.getItem("borrow-interest")) || 0;
  const initialRepay = () =>
    Number(window.localStorage.getItem("borrow-repay")) || 0;
  const initialLoanWInterest = () =>
    Number(window.localStorage.getItem("borrow-loanwinterest")) || 0;
  const initialBorrowPNL = () =>
    Number(window.localStorage.getItem("borrow-pnl$")) || 0;
  const initialPNLAda = () =>
    Number(window.localStorage.getItem("borrow-pnlAda")) || 0;
  const initialLoanValueDollar = () =>
    Number(window.localStorage.getItem("borrow-loanvalue$")) || 0;
  const initialRepayInterest = () =>
    Number(window.localStorage.getItem("borrow-repayinterest")) || 0;
  const initialBreakEven = () =>
    Number(window.localStorage.getItem("borrow-ada-breakeven")) || 0;
  const initialLoanValue = () =>
    Number(window.localStorage.getItem("borrow-loan-value")) || 0;
  const initialADAinterestcharged = () =>
    Number(window.localStorage.getItem("borrow-interest-charged")) || 0;
  const initialRepayInterestPlusLoan = () =>
    Number(window.localStorage.getItem("borrow-repay-plus-loan")) || 0;

  /* Set Value State */
  const [ADAPriceSOL, setADAPriceSOL] = useState(initialSOL);
  const [adaEOL, setADAEol] = useState(initialEOL);
  const [repaymentBorrow, setRepaymentBorrow] = useState(initialRepay);
  const [lengthBorrow, setLengthBorrow] = useState(initialLength);
  const [interestBorrow, setInterestBorrow] = useState(initialInterest);
  const [loanValueBorrow, setLoanValueBorrow] = useState(initialLoanValue);
  const [loanWInterestBorrow, setLoanInterestBorrow] = useState(
    initialLoanWInterest
  );
  const [profitLoss, setProfitLoss] = useState(initialBorrowPNL);
  const [profitLossADA, setProfitLossADA] = useState(initialPNLAda);
  const [loanValueDollars, setLoanValueDollars] = useState(
    initialLoanValueDollar
  );
  const [repaymentInterestBorrow, setRepaymentInterestBorrow] = useState(
    initialRepayInterest
  );
  const [adaInterestCharged, setAdaInterestCharged] = useState(
    initialADAinterestcharged
  );
  const [adaBreakEvenPrice, setADABreakEvenPrice] = useState(initialBreakEven);
  const [repayInterestPlusLoan, setRepayInterestPlusLoan] = useState(
    initialRepayInterestPlusLoan
  );

  console.log(loanWInterestBorrow);

  /* ADA price at start of loan function */
  const SetADAPriceSOL = (e) => {
    setADAPriceSOL(e.target.value);
  };

  /* Loan value in ADA requested */

  const SetLoanValueADA = (e) => {
    setLoanValueBorrow(e.target.value);
  };

  /* End of loan ADA price or buy back price */
  const SetBuyBack = (e) => {
    setADAEol(e.target.value);
  };

  /* Interest rate set function for loan */
  const SetInterestBorrow = (e) => {
    setInterestBorrow(e.target.value);
  };

  const ChangeFocus = (e) => {
    setTimeout(() => {
      e.target.value = "";
    }, 10);
  };

  /* UseEffect manages updating the various inputs without direct input
    that require calculation */

  useEffect(() => {
    setLoanValueDollars(
      Number.isInteger(parseFloat(ADAPriceSOL * loanValueBorrow))
        ? parseFloat(ADAPriceSOL * loanValueBorrow)
        : parseFloat(ADAPriceSOL * loanValueBorrow).toFixed(2)
    );
    setRepaymentBorrow(
      Number.isInteger(parseFloat(adaEOL * loanValueBorrow))
        ? parseFloat(adaEOL * loanValueBorrow)
        : parseFloat(adaEOL * loanValueBorrow).toFixed(2)
    );
    if (ADAPriceSOL === 0) {
      setADABreakEvenPrice(0);
    } else {
      setADABreakEvenPrice(
        Number.isInteger(parseFloat(ADAPriceSOL * (1 - (adaInterestCharged / loanWInterestBorrow))))
          ? parseFloat(ADAPriceSOL * (1 - (adaInterestCharged / loanWInterestBorrow)))
          : parseFloat(ADAPriceSOL * (1 - (adaInterestCharged / loanWInterestBorrow))).toFixed(6)
      );
    }

    setLoanInterestBorrow(
      Number.isInteger(parseFloat(loanValueBorrow * (1 + interestBorrow / 100)))
        ? parseFloat(loanValueBorrow * (1 + interestBorrow / 100))
        : parseFloat(loanValueBorrow * (1 + interestBorrow / 100)).toFixed(2)
    );
    setAdaInterestCharged(
      Number.isInteger(parseFloat(loanWInterestBorrow - loanValueBorrow))
        ? parseFloat(loanWInterestBorrow - loanValueBorrow)
        : parseFloat(loanWInterestBorrow - loanValueBorrow).toFixed(2)
    );
    setRepaymentInterestBorrow(
      Number.isInteger(parseFloat(adaEOL * adaInterestCharged))
        ? parseFloat(adaEOL * (adaInterestCharged))
        : parseFloat(adaEOL * adaInterestCharged).toFixed(2)
    );
    setRepayInterestPlusLoan(
      Number.isInteger(
        parseFloat(repaymentInterestBorrow) + parseFloat(repaymentBorrow)
      )
        ? parseFloat(repaymentInterestBorrow) + parseFloat(repaymentBorrow)
        : (
            parseFloat(repaymentInterestBorrow) + parseFloat(repaymentBorrow)
          ).toFixed(4)
    );
    setProfitLoss(
      Number.isInteger(
        parseFloat(loanValueDollars) - parseFloat(repayInterestPlusLoan)
      )
        ? parseFloat(loanValueDollars) - parseFloat(repayInterestPlusLoan)
        : (
            parseFloat(loanValueDollars) - parseFloat(repayInterestPlusLoan)
          ).toFixed(2)
    );
    const setZero = () =>
      adaEOL === 0
        ? 0
        : Number.isInteger(parseFloat(profitLoss / adaEOL))
        ? parseFloat(profitLoss / adaEOL)
        : parseFloat(profitLoss / adaEOL).toFixed(2);
    setProfitLossADA(setZero);

    /* Data of the various states lifted up as an object via setData prop */
    const summaryBorrowData = [
      { Name: "ADA Price @ Start of Loan", Data: ADAPriceSOL },
      { Name: "Loan Value w/Interest ADA", Data: loanWInterestBorrow },
      { Name: "ADA Price @ End of Loan", Data: adaEOL },
      { Name: "Profit/Loss ADA", Data: profitLossADA },
      { Name: "Profit/Loss ($)", Data: profitLoss },
    ];
    props.summaryBorrowData(summaryBorrowData);
    const borrowData = {
      loanValueBorrow: loanValueBorrow,
      loanWInterestBorrow: loanWInterestBorrow,
      loanValueDollars: loanValueDollars,
      repayInterestPlusLoan: repayInterestPlusLoan,
      adaEOL: adaEOL
    };
    props.data(borrowData);
  }, [
    adaEOL,
    ADAPriceSOL,
    repaymentBorrow,
    lengthBorrow,
    interestBorrow,
    loanValueBorrow,
    loanWInterestBorrow,
    profitLoss,
    profitLossADA,
    loanValueDollars,
    repaymentInterestBorrow,
    adaInterestCharged,
    adaBreakEvenPrice,
    repayInterestPlusLoan,
  ]);

  /* Loan Length function */
  const SetLoanLengthBorrow = (e) => {
    setLengthBorrow(e.target.value);
  };

  const CNFTHandler = () => {
    props.openCNFT(true);
    props.closeBorrow(false);
  };

  const PreviousHandler = () => {
    props.previous(true);
  };

  useEffect(() => {
    window.localStorage.setItem("borrow-length", lengthBorrow);
    window.localStorage.setItem("borrow-sol", ADAPriceSOL);
    window.localStorage.setItem("borrow-eol", adaEOL);
    window.localStorage.setItem("borrow-interest", interestBorrow);
    window.localStorage.setItem("borrow-repay", repaymentBorrow);
    window.localStorage.setItem("borrow-loanwinterest", loanWInterestBorrow);
    window.localStorage.setItem("borrow-pnl$", profitLoss);
    window.localStorage.setItem("borrow-pnlAda", profitLossADA);
    window.localStorage.setItem("borrow-loanvalue$", loanValueDollars);
    window.localStorage.setItem(
      "borrow-repayinterest",
      repaymentInterestBorrow
    );

    window.localStorage.setItem("borrow-interest-charged", adaInterestCharged);
    window.localStorage.setItem("borrow-ada-breakeven", adaBreakEvenPrice);
    window.localStorage.setItem("borrow-loan-value", loanValueBorrow);
    window.localStorage.setItem(
      "borrow-repay-plus-loan",
      repayInterestPlusLoan
    );
  }, [
    adaEOL,
    ADAPriceSOL,
    repaymentBorrow,
    lengthBorrow,
    interestBorrow,
    loanValueBorrow,
    loanWInterestBorrow,
    profitLoss,
    profitLossADA,
    loanValueDollars,
    repaymentInterestBorrow,
    adaInterestCharged,
    adaBreakEvenPrice,
    repayInterestPlusLoan,
  ]);

  return (
    <>
      <ProcessHeader
        blue={props.blue}
        white={props.white}
        h2="Borrow/Short ADA"
      />
      <div className={props.container}>
        <Input
          onFocus={ChangeFocus}
          title="Length(Days)"
          name="borrow-length-days"
          onChange={SetLoanLengthBorrow}
          id="loan-length"
          type="number"
          inputClass={classes.input}
          value={lengthBorrow}
        ></Input>
        <ADAprice></ADAprice>
        <Input
          onFocus={ChangeFocus}
          title="ADA Price($) @ Start of Loan/Sale of ADA"
          name="ada-price-sol"
          onChange={SetADAPriceSOL}
          inputClass={classes.input}
          id="ada-price-sol"
          value={ADAPriceSOL}
          type="number"
        ></Input>
        <Input
          onFocus={ChangeFocus}
          title="Loan Value ADA"
          name="borrow-loan-value-ada"
          onChange={SetLoanValueADA}
          inputClass={classes.input}
          id="loan-value"
          value={loanValueBorrow}
          type="number"
        ></Input>
        <Input
          title="Loan Value ($)"
          name="borrow-loan-value-dollar"
          readonly='readonly'
          id="loan-value"
          type="number"
          value={loanValueDollars}
        ></Input>
        <Input
          onFocus={ChangeFocus}
          title="Interest %"
          name="borrow-interest"
          onChange={SetInterestBorrow}
          inputClass={classes.input}
          id="loan-interest"
          value={interestBorrow}
          type="number"
        ></Input>
        <Input
          title="Loan w/Interest ADA"
          name="borrow-loan-interest-with-ada"
          readonly='readonly'
          id="loan-value-interest"
          type="number"
          value={loanWInterestBorrow}
        ></Input>
        <Input
          title="Interest Charged ADA"
          name="borrow-loan-interest-charged-ada"
          readonly='readonly'
          id="interest-charged-ada"
          type="number"
          value={adaInterestCharged}
        ></Input>
        <Input
          title="Break Even ADA Price ($)"
          readOnly='readonly'
          type="number"
          value={adaBreakEvenPrice}
        ></Input>
        <Input
          onFocus={ChangeFocus}
          title="Buy back ADA Price ($)"
          name="buy-back-ada-price"
          onChange={SetBuyBack}
          type="number"
          inputClass={classes.input}
          id="buy-back"
          value={adaEOL}
        ></Input>
        <Input
          title="Repayment($) Exclude Interest"
          name="repay-exclude-interest-dollar"
          readOnly='readonly'
          id="repay"
          type="number"
          value={repaymentBorrow}
        ></Input>
        <Input
          title="Interest Repaid ($)"
          name="interest-repay-dollar"
          readOnly='readonly'
          id="repay-interest"
          type="number"
          value={repaymentInterestBorrow}
        ></Input>
        <Input
          title="Interest + Loan ($)"
          name="interest-plus-loan-dollar"
          readOnly='readonly'
          id="repay-interest-plus-loan"
          type="number"
          value={repayInterestPlusLoan}
        ></Input>
        <Input
          title="Profit/Loss($)"
          name="profit-loss-dollar"
          readOnly='readonly'
          id="profit-loss"
          type="number"
          value={profitLoss}
        ></Input>
        <Input
          title="Profit/Loss ADA"
          name="profit-loss-ada"
          readOnly='readonly'
          id="profit-loss-ada"
          type="number"
          value={profitLossADA}
        ></Input>
      </div>
      <div className={classes.buttoncontainer}>
        <Button
          style={classes.buttonprocess}
          onClick={PreviousHandler}
          title="Previous"
        />
        <Button
          style={classes.buttonprocess}
          onClick={CNFTHandler}
          title="Next"
        />
      </div>
    </>
  );
}

export default Borrow;

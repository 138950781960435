import React, { useContext, useEffect, useRef, useState } from "react";
import Input from "../../Input/Input";
import classes from "./CNFT.module.css";
import cniphtyStyle from "../CNiphtyProphet.module.css";
import styles from "../../Dropdown/Dropdown.module.css";
import axios from "axios";
import AdjustProjectList from "../../UI/AdjustProjectList";
import cnftArray from "../../CNFTProjectData";
import Dropdown from "../../Dropdown/Dropdown";
import DropdownUl from "../../DropdownUl/DropdownUl";
import add from "classnames";
import Button from "../../UI/Button";
import ProcessHeader from "../ProcessHeader";

const CNFT = (props) => {
  const initialPurchase = () =>
    Number(window.localStorage.getItem("cnft-purchase")) || 0;
  const initialSales = () =>
    Number(window.localStorage.getItem("cnft-sale")) || 0;
  const initialPLADA = () =>
    Number(window.localStorage.getItem("cnft-pnl")) || 0;

  const { loanValueBorrow, loanWInterestBorrow } = { ...props.data };

  const [floor, setFloor] = useState();
  const [purchasePriceCNFT, setPurchasePriceCNFT] = useState(initialPurchase);
  const [salesPriceCNFT, setSalesPriceCNFT] = useState(initialSales);
  const [profitLossADACNFT, setProfitLossADACNFT] = useState(initialPLADA);
  const [difference, setDifference] = useState();
  const [loanValue, setLoanValue] = useState(loanValueBorrow);
  const [loanValueInterest, setLoanValueInterest] = useState(
    loanWInterestBorrow
  );
  const [text, setText] = useState('');

  const changePurchasePriceHandler = (e) => {
    setPurchasePriceCNFT(e.target.value);
  };

  const changeSalesPriceHandler = (e) => {
    setSalesPriceCNFT(e.target.value);
    console.log(props.data);
  };

  const projectList = [];
  const projectListUl = [];

  const [title, setTitle] = useState("");

  for (let x of cnftArray) {
    projectList.push(
      <Dropdown
        name="cnft"
        key={x.id}
        text={x.project}
        idDropdown={x.projectID}
        textDropdown={x.project}
        valueDropdown={x.policyID}
        pID={`${x.projectID}${x.policyID}`}
      ></Dropdown>
    );
  }

  for (let i of cnftArray) {
    projectListUl.push(
      <DropdownUl
        key={i.id}
        forDropdown={i.projectID}
        textDropdown={i.project}
      ></DropdownUl>
    );
  }

  const ChangeProject = (e) => {
    let policyManual;
    let selectedPolicyCNFT;
    if (e.length > 1) {
      policyManual = e;
      selectedPolicyCNFT = null;
    } else {
      selectedPolicyCNFT = e.target.value;
      console.log(e)
      console.log(e.target)
      policyManual = null;
    }
    function sortPolicy() {
      if (policyManual !== null) {
        return policyManual;
      } else {
        return selectedPolicyCNFT;
      }
    }
    const config = { headers: { Accept: "application/json" } };

    const params = {
      policy: sortPolicy(),
    };
    axios
      .get(`https://api.opencnft.io/1/policy/${params.policy}`, config)
      .then((res) => {
        console.log(res);
        const floorNum = (res.data.floor_price /= Math.pow(10, 6));
        const floorStr = `${floorNum} ADA`;
        const assetName = res.data.highest_sale.asset_name;
        setText(assetName.replace(/[0-9]/g, ''))
        console.log(res.data.highest_sale.asset_name)
        setFloor(floorStr);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    const chilled = document.querySelector("#project27");
    chilled.checked = true;
    ChangeProject("c56d4cceb8a8550534968e1bf165137ca41e908d2d780cc1402079bd");
  }, []);

  useEffect(() => {
    console.log(title);
    const pnlAda = () =>
      salesPriceCNFT === 0
        ? 0
        : parseFloat(
            (salesPriceCNFT - loanWInterestBorrow + difference).toFixed(2)
          );

    setProfitLossADACNFT(pnlAda);
    setDifference(
      Number.isInteger(parseFloat(loanValueBorrow - purchasePriceCNFT))
        ? parseFloat(loanValueBorrow - purchasePriceCNFT)
        : parseFloat(loanValueBorrow - purchasePriceCNFT).toFixed(2)
    );
  }, [
    salesPriceCNFT,
    loanWInterestBorrow,
    loanValueBorrow,
    purchasePriceCNFT,
    difference,
    title,
    floor,
  ]);

  useEffect(() => {
    window.localStorage.setItem("cnft-purchase", purchasePriceCNFT);
    window.localStorage.setItem("cnft-sale", salesPriceCNFT);
    window.localStorage.setItem("cnft-pnl", profitLossADACNFT);
  }, [purchasePriceCNFT, salesPriceCNFT, profitLossADACNFT]);

  const ChangeFocus = (e) => {
    setTimeout(() => {
      e.target.type = "number";
      e.target.value = "";
    }, 10);
  };


  const CryptoHandler = () => {
    props.closeCNFT(false);
    props.openCrypto(true);
    const CNFTData = [
      { Name: "NFT", Data: text },
      { Name: "Purchase Price NFT", Data: purchasePriceCNFT },
      { Name: "Sale Price NFT", Data: salesPriceCNFT },
      { Name: "Profit/Loss ADA", Data: profitLossADACNFT },
    ];
    props.cnftData(CNFTData);
  };

  const PreviousHandler = () => {
    props.previous(true);
    props.closeCNFT(false);
  };

  return (
    <>
      <ProcessHeader h2='CNFT Trade' blue={props.blue} white={props.white}/>
      <div className={props.container}>
          <AdjustProjectList
            containerClass={add(styles.selectOptionsContainer, classes.width)}
            onClick={ChangeProject}
            boxClass={styles.selectOptionsDivBox}
            listOne={projectList}
            inputClass={cniphtyStyle.input}
            listTwo={projectListUl}
            iconClass={styles.selectIcon}
            selectListClass={styles.selectList}
            listContainerClass={styles.listContainer}
          />

        <Input readonly title="Floor Price" value={floor} />
        <Input
          readOnly='readonly'
          type="number"
          title="Borrowed Amount ADA"
          value={loanValue}
        />
        <Input
          title="Purchase Price"
          value={purchasePriceCNFT}
          onFocus={ChangeFocus}
          inputClass={cniphtyStyle.input}
          onChange={changePurchasePriceHandler}
        />
        <Input
          title="Borrowed/Purchase Difference"
          type="number"
          value={difference}
          readOnly='readonly'
        />
        <Input
          title="Sale Price"
          value={salesPriceCNFT}
          onFocus={ChangeFocus}
          inputClass={cniphtyStyle.input}
          onChange={changeSalesPriceHandler}
        />
        <Input
          readOnly='readonly'
          type="number"
          title="Borrowed Payment ADA"
          value={loanValueInterest}
        />
        <Input
          title="Profit/Loss ADA"
          value={profitLossADACNFT}
          type="number"
          readOnly='readonly'
        />
      </div>
      <div className={cniphtyStyle.buttoncontainer}>
        <Button
          style={cniphtyStyle.buttonprocess}
          onClick={PreviousHandler}
          title="Previous"
        />
        <Button
          style={cniphtyStyle.buttonprocess}
          onClick={CryptoHandler}
          title="Next"
        />
      </div>
    </>
  );
};

export default CNFT;

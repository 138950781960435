import userEvent from "@testing-library/user-event";
import React, { createContext, useEffect, useRef } from "react";
import classes from "./Dropdown.module.css";


function Dropdown(props) {

  return (
      <div className={classes.selectOptionsDiv}>
        <input
          key={props.key}
          onChange={props.onChange}
          className={classes.selectOptionsOption}
          type="radio"
          id={props.idDropdown}
          value={props.valueDropdown}
          name={props.name}
          text={props.textDropdown}
          checked={props.checked}
        />
        <p id={props.pID} className={classes.selectOptionsText}>{props.textDropdown}</p>
      </div>
  );
}

export default Dropdown;

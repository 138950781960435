
class NFTprops {
    constructor(id, policyID, project, projectID) {
        this.id = id;
        this.policyID = policyID;
        this.project = project;
        this.projectID = projectID
    }
}


const adaDiggies1 = new NFTprops(1, '09b74bdb51459166388c96ded33262892c30e28b8e1e12d33600a3ed', 'ADA Diggies 1');
const adaDiggies2 = new NFTprops(2, 'd1031455a8cff011e461c249aa080b026873bd2f77aa0472f21f36d9', 'ADA Diggies 2');
const adaHandle = new NFTprops(3, 'f0ff48bbb7bbe9d59a40f1ce90e9e9d0ff5002ec48f232b49ca0fb9a', 'ADA Handle');
const adaInvadazS1 = new NFTprops(4, '2d503a06893ced6641243f987403b2e094d41dc46153286c9f49b2e5', 'ADA Invadaz S1');
const adaNinjasS1 = new NFTprops(5, '83c0ab67afc9148bd1571b7a14de1df03cd5624f5992d3b8ec84d6fb', 'ADA Ninjas S1');
const adaNinjasS2 = new NFTprops(6, '83cb87b69639e20d7c99755fcfc310fb47882c3591778a3c869ea34c', 'ADA Ninjas S2');
const apeNation = new NFTprops(7, '58b9f55e6ea9828dea7a8d9f49420171c6360f99b5e6e86de5fdb644', 'Ape Nation');
const apeSociety = new NFTprops(8, 'dac355946b4317530d9ec0cb142c63a4b624610786c2a32137d78e25', 'Ape Society');
const aquafarmers = new NFTprops(9, '86ec26a91051e4d42df00b023202e177a0027dca4294a20a0326a116', 'Aquafarmers');
const artifactMoon = new NFTprops(10, 'e5a2bcc51466942a9db1da62471a1b682bde3abcebafee2c7fb1e378', 'Artifact Moon');
const benjaminGroup = new NFTprops(11, 'f7f5a12b681be1a2c02054414a726fefadd47e24b0343cd287c0283d', "Benjamin's Group");
const berry = new NFTprops(12, 'b863bc7369f46136ac1048adb2fa7dae3af944c3bbb2be2f216a8d4f', 'Berry');
const blockOwlsPlutus = new NFTprops(13, '7ea3e7719938f8acd700327d6c9dffd7523a02dce1d9891b5f3a452d', 'BlockOwls - Plutus Collection');
const blockOwlsHydra = new NFTprops(14, 'bff82d31352d9bdfdb49e243ab74af715488631f330b2cf064178f90', 'BlockOwls - Hydra Collection');
const blockOwlsC1 = new NFTprops(15, 'befa6559b71f217bfc8ab2fd6666956f3cae8e54b4b51d7a3c87cf02', 'BlockOwls - C#1 Collection');
const blockOwlsTygar = new NFTprops(16, '3f4360edd7f689b637b44587150e71453a51ba29655336ff374c201c', 'BlockOwls - Tygar Collection');
const bossCatRocketParts = new NFTprops(17, '761ed110b4040e153ffc20bd2203f2e7d57d610a0cf0eae6167e574d', 'Boss Cat Rocket Parts');
const bossPlanetRealEstate = new NFTprops(18, '5a2cdc6e3aa9612fe4676672f443e7efd39c309d45e7919a4bf27750', 'Boss Planet Real Estate');
const bossPlanetVox = new NFTprops(19, 'a8c68ff1e130c79cd0cad73357670b3b4fd11a0708319a99be14e860', 'Boss Planet Vox Cats');
const bossCatRocketClub = new NFTprops(20, 'c364930bd612f42e14d156e1c5410511e77f64cab8f2367a9df544d1', 'Boss Cat Rocket Club');
const brightLeaf = new NFTprops(21, '2bf500f9dbef559c647d7df4d59f8ddf7f72371c43c82d61f251de21', 'Bright Leaf');
const apeSocietyCabins = new NFTprops(22, 'd4e087164acf8314f1203f0b0996f14908e2a199a296d065f14b8b09', 'Ape Society - Cabins');
const cardanoCrocsClub = new NFTprops(23, 'aa19d5f5ae9b6c93c8e278851194553ddd4789d77f86d3ad2f7480d8', 'Cardano Crocs Club');
const cardanoWarriors = new NFTprops(24, '8f80ebfaf62a8c33ae2adf047572604c74db8bc1daba2b43f9a65635', 'Cardano Warriors');
const cardano4Speed = new NFTprops(25, 'e6363c3c57db3330b3947e106e53e77c8a11558a767df29a74762f79', 'CARdano4SPEED');
const cardanoBits = new NFTprops(26, '1131301ad4b3cb7deaddbc8f03f77189082a5738c0167e1772233097', 'CardanoBits');
const cardanoKidz = new NFTprops(27, '6afd2b286ec8d119f803e72ef14a487ea380a9ae094527e001160caa', 'CardanoKidz');
const chilledKongz = new NFTprops(28, 'c56d4cceb8a8550534968e1bf165137ca41e908d2d780cc1402079bd', 'Chilled Kongz');
const chroniclesDragonverse = new NFTprops(29, '9f8a6108afc7d8682ab2606fb2299dc76a9eaa22aa0f3db8f9ca1362', 'Chronicles of Dragonverse');
const clayBudzP2 = new NFTprops(30, 'b7d36cb1e1cac6bb114c2d7461ee8a3862024160ef5bc5fcd7ba53c7', 'Clay Budz Part 2');
const clayMatesS2 = new NFTprops(31, '9c1510b8e290e285caa5b56feb7df5842554f20b09cafbc82410fb6f', 'Clay Mates S2');
const clayMatesCharlie = new NFTprops(32, '6e9ddb1bfafae89c4b026a4d3c1721bf6948027fea9ec03658f0c72c', 'Clay Mates - Charlie Birdkinson');
const clayNation = new NFTprops(33, '40fa2aa67258b4ce7b5782f74831d46a84c59a0ff0c28262fab21728', 'Clay Nation');
const clayNationPitches = new NFTprops(34, '13e3f9964fe386930ec178d12a43c96a7f5841270c2146fc509a9f3e', 'Clay Nation Pitches');
const clumsyGhosts = new NFTprops(35, 'b000e9f3994de3226577b4d61280994e53c07948c8839d628f4a425a', 'Clumsy Ghosts');
const cnftconLegacyPass = new NFTprops(36, '5fa72fbeecbe80a3e15de1cacab54ba5e310e2c36ae85351132ed4ad', 'CNFTcon Legacy Pass');
const cornucopiasBubblejett = new NFTprops(37, 'a4badea66770768b02ff7b9674d445f13016ce901804f45844e00ebd', 'Cornucopias Bubblejett');
const cornucopiasBubblejett22 = new NFTprops(38, 'e282271ec9251ba23fb123b0f53618b35cf5a6cde4170c003a0ebf13', 'Cornucopias Bubblejett 22');
const cornucopiasGTIJavelin = new NFTprops(39, '086849cd9f672e731e0d9590a2d28a6a690ffa2f73bae0e1970f0491', 'Cornucopias GTI Javelin 22');
const cornucopiasLandSaleZones = new NFTprops(40, '07b39a8ead0ef1e3054e816a3b6910060beaf2210fded63fb90ce137', 'Cornucopias Land Sale - Zones 1-3');
const deadRabbitSociety = new NFTprops(41, 'de2340edc45629456bf695200e8ea32f948a653b21ada10bc6f0c554', 'Dead Rabbits Society');
const deadRabbitsTicket = new NFTprops(42, '6e7bd53adc62886cb404a7368c54c7940f5838e0a456512df04e96e7', 'Dead Rabbits Day Tickets');
const deadpxlz = new NFTprops(43, '1ec85dcee27f2d90ec1f9a1e4ce74a667dc9be8b184463223f9c9601', 'Deadpxlz');
const degenCryptoClub = new NFTprops(44, '2feb543256ec14b0ccde8892ccbecbf5fa0e6f36f19f6cdc63930c2c', 'Degen Crypto Club');
const discoSolaris = new NFTprops(45, 'd0112837f8f856b2ca14f69b375bc394e73d146fdadcc993bb993779', 'Disco Solaris');
const eikonikosGenesis = new NFTprops(46, 'd1b21921e3cc1b7559c2691365ab3b9b1e495e1b0391213b71577430', 'EIKONIKOS - Genesis Passports');
const elysiumTitans = new NFTprops(47, 'bd369dc746e4a872a55fb9f0472d5fe30f3ba3fe0b72f280fe2455a5', 'Elysium Titans');
const equineNFTPioneer = new NFTprops(48, '30ed3d95db1d6bb2c12fc5228a2986eab4553f192a12a4607780e15b', 'EquineNFT Pioneer Horses');
const filthyRichHorses = new NFTprops(49, '3cfaab5893f90eee3d20c6bc69716568b69e7f480e7df9c1f3dd0232', 'Filthy Rich Horses');
const fortGottenE2 = new NFTprops(50, '48ef9fb80a0ad2fd9f3d5b981ef3bfac2bae84137523217b387a775b', 'Fort Gottent E2');
const frescos = new NFTprops(51, '5c80d8420b415e6f277d830e780190f288993019108bebecf5ccf9e1', 'Frescos');
const geniusYieldMascot = new NFTprops(52, 'ae5e7966d35e154a3b5bdffe5c1b8a1ea981b4c35dd2352fc96766e0', 'Genius Yield - Mascot Contest');
const ghostChain = new NFTprops(53, '8021c0ab3285cc3cfff2b7e61e96ece565fb37279b67666741587b54', 'Ghostchain');
const goatTribe = new NFTprops(54, 'c281975562f394761771f13f599881517fa8455946e7e30454de22da', 'Goat Tribe');
const clayNationGC = new NFTprops(55, '94da605878403d07c144fe96cd50fe20c16186dd8d171c78ed6a8768', 'Clay Nation x GC');
const hypeSkulls = new NFTprops(56, '2f459a0a0872e299982d69e97f2affdb22919cafe1732de01ca4b36c', 'H.Y.P.E Skulls');
const halloweenGoats = new NFTprops(57, '961cd58296989d288d8ad39507e81fd40c3e562da89c90e5632061da', 'Goat Tribe - Helloween 21');
const jellyCubes = new NFTprops(58, '3ee441f40fe395a2e98eea1df7cf8816b0fca3d5d164893596ce306d', 'Jelly Cubes');
const hypebeasts = new NFTprops(59, 'b001076b34a87e7d48ec46703a6f50f93289582ad9bdbeff7f1e3295', 'Hypebeasts');
const introverts = new NFTprops(60, 'd79181749db228d10c98501a7e1728585780bcf133b7b3df953a9017', 'Introverts');
const jarHeads = new NFTprops(61, 'eba255af1c4234ea258d3d0ef1ae3bc715432de4b3e6ba481214a4ca', 'Jar Heads');
const knightsCornucopias = new NFTprops(62, '788cc573eb32a5378f1d25e6414228c0b7effd788e2f6fb2b11471f3', 'Knights of Cornucopias');
const lazyLlamas = new NFTprops(63, '9e7b9873fc65bc20ada9739b85d15057603577c1777c7325bba9ae9c', 'Lazy Llamas');
const lazyLlamasMutants = new NFTprops(64, '4c68c9f9580b7c68b215073c68294e920856c15392df361b83628880', 'Lazy Llamas - Mutants');
const lionLegendsS1 = new NFTprops(65, '7eb7cf060a9740b63de68afb1c0fde7f74f2895c981573259046ae3f', 'LionLegends - S1');
const lionLegendsS2 = new NFTprops(66, '9d4c40c114d3d69d4f8209e205686db296683073c0ca5c63a8d2456e', 'LionLegends - S2');
const lionLegendsS3 = new NFTprops(67, 'a8830ba155386c9837f7fd86ae8917265747fdb55a440634f058639d', 'LionLegends - S3');
const mandrillz = new NFTprops(68, '3f75c1332868430e28e156f6309836de8df19b620ab604c166741836', 'Mandrillz');
const marsBirds = new NFTprops(69, 'ceb8a6f66d8abf778e111ffb982626e6e795d8ef15e7261ea98738d2', 'MarsBirds');
const meltingMoonboy = new NFTprops(70, '637bae5845e801779651d997bedc2dad53ddbe0d5f940b4ff723d769', 'Melting Moonboy');
const metaPXLZ = new NFTprops(71, 'd6e3ae03a94c9748189f41e9d180f91e971f18909ea52559c977c259', 'metaPXLZ');
const mocossiITOs = new NFTprops(72, '5d5b205252b9f5016422d0eace869d7fd45074a4ea4b6c1dc78d1705', 'Mocossi - ITOs');
const mutantToads = new NFTprops(73, '1cda8886f4cbab2217346187a923b3be670e42ef5778a0ad7d25358c', 'Mutant Toads');
const oldMoneyBackBills = new NFTprops(74, '4552d6234e2a9cf2615220f9dbe1b233c4c2dccbc8d872dcae9a3795', 'Old Money - Back of Bills');
const outerspace = new NFTprops(75, 'f4dac49e9eef19212400cb2e99d1ba2ff954029f2548048792f67843', 'Spaceturion Card');
const overExposed = new NFTprops(76, '5fd604255c9c14dbf3930b78aeb2ccb70fb553bccf898696e92d5191', 'Over Exposed');
const overExposedMekanism = new NFTprops(77, 'ffa56051fda3d106a96f09c3d209d4bf24a117406fb813fb8b4548e3', 'Over Exposed - Mekanism');
const parisianBuilding = new NFTprops(78, '9cdd9ff7f637b3de66ac2b41afad7ffc24e679312075f02a1a3afe96', 'Parisian Building');
const pavia = new NFTprops(79, '4bf184e01e0f163296ab253edd60774e2d34367d0e7b6cbc689b567d', 'Pavia');
const pavs = new NFTprops(80, '852526a77c45662e981181ed9b0afca13cfd8e45c169a20b37832ea7', 'Pavs');
const pendulum = new NFTprops(81, 'a616aab3b18eb855b4292246bd58f9e131d7c8c25d1d1d7c88b666c4', 'Pendulum');
const phantomKey = new NFTprops(82, '1f9c0c14d211117b82a61602f98f18274bc5086ad1231614715923d0', 'Phantom Key');
const puurrtyCats = new NFTprops(83, 'f96584c4fcd13cd1702c9be683400072dd1aac853431c99037a3ab1e', 'Puurrty Cats Society');
const raisonDetreCryo = new NFTprops(84, 'd50e69069b2b8b6daebe870a08a253791dcabb27a22e08e76115caaf', "Raison D'etre - Cryo-Genesis");
const saltySeagullsSociety = new NFTprops(85, '5329a9b87459e1df8e86ce56bae4b3770862c0969011e6faf474b65f', 'Salty Seagulls Society');
const sealSociety = new NFTprops(86, '83af574378d100ffb4d657010d117c92038b9fbc9129c3b0dc4ea96b', 'Seal Society');
const smoothYetiMtnClub = new NFTprops(87, 'eaa972045049185981aca9f4aaad38bc307776c593e4a849d3802a87', 'Smooth Yeti Mountain Club');
const smoothYetiSnowmobileParts = new NFTprops(88, '4a3f0261200f39b4d03377e3d4bce6554e3af0236b2c1b4be8d8d8ca', 'Smooth Yeti Snowmobile Parts');
const spacePugsAlpha = new NFTprops(89, '4e5f3e165b93c99d922111d9818c03f33e8da8a9f6ae1465c7869a21', 'Space Pugs Alpha');
const spaceBudz = new NFTprops(90, 'd5e6bf0500378d4f0da4e8dde6becec7621cd8cbf5cbb9b87013d4cc', 'Spacebudz');
const uglyBros = new NFTprops(91, '12f9d9446c422caa870948bae1b8844c26c64958943a3954103b034f', 'Ugly Bros');
const uglyBrosXmas = new NFTprops(92, '89c9857b0239f8d1074a8f66038c6734297c18538613c710673ea7a6', 'Ugly Bros: Xmas');
const uglyBrosDefinitive = new NFTprops(93, 'd7726f6b882c6fc2ca0cd96c51e5328e1d577d789b085ee0fbe23bf7', 'Ugly Bros: The Definitive');
const uglyBrosValentine = new NFTprops(94, 'e6033a3de395cbbc358ece71e1399f78e8b0f4af75948d2af9ce939c', 'Ugly Bros: Valentine');
const unsignedAlgorithms = new NFTprops(95, '0e14267a8020229adc0184dd25fa3174c3f7d6caadcb4425c70e7c04', 'Unsigned Algorithms');
const voyagePlanets = new NFTprops(96, '7371b76a7cfb71c5c70618fd2b27f357a6eb84c38ad4f92fed1164f2', 'Voyage - Planets');
const vyFI = new NFTprops(97, '6d8dfd8e196b6d233fa6431d6dd39da1a1e8340637ed90d0681ed1dd', 'VYFI');
const wildTangz = new NFTprops(98, '33568ad11f93b3e79ae8dee5ad928ded72adcea719e92108caf1521b', 'Wild Tangz')
const woodLordsEarlyBird = new NFTprops(99, '3d193eec8d59640cf458008694713a5ad2f82dc36a674b8602e14018', 'Wood Lords Early Bird');
const yummiUniverseNaru = new NFTprops(100, 'b1814c6d3b0f7a42c9ee990c06c9d504a42bb22bf0e34e7908ae21b2', 'Yummy Universe - Naru');
const yummiUniverseSnoopy = new NFTprops(101, '7d2d1ee92be476d47043d26ac5a45402e3bdb50a34aa8d716babeefc', 'Yummy Universe - Snoopy Naru');

const cnftArray = [adaDiggies1, adaDiggies2, adaHandle, adaInvadazS1, adaNinjasS1, adaNinjasS2, apeNation, apeSociety, apeSocietyCabins, aquafarmers, artifactMoon, benjaminGroup, berry, blockOwlsC1, blockOwlsHydra, blockOwlsPlutus, blockOwlsTygar, bossCatRocketParts, bossCatRocketClub,
    bossPlanetRealEstate, bossPlanetVox, brightLeaf, cardanoCrocsClub, cardanoWarriors, cardano4Speed, cardanoBits, cardanoKidz, chilledKongz, chroniclesDragonverse, clayBudzP2, clayMatesS2, clayMatesCharlie, clayNation, clayNationGC, clayNationPitches, clumsyGhosts, cnftconLegacyPass, cornucopiasBubblejett, cornucopiasBubblejett22,
    cornucopiasGTIJavelin, cornucopiasLandSaleZones, deadRabbitSociety, deadRabbitsTicket, deadpxlz, degenCryptoClub, discoSolaris, eikonikosGenesis, elysiumTitans, equineNFTPioneer, filthyRichHorses, fortGottenE2, frescos, geniusYieldMascot, ghostChain, goatTribe, halloweenGoats, hypeSkulls, hypebeasts, introverts,
    jarHeads, knightsCornucopias, lazyLlamas, lazyLlamasMutants, lionLegendsS1, lionLegendsS2, lionLegendsS3, mandrillz, marsBirds, meltingMoonboy, metaPXLZ, mocossiITOs, mutantToads, oldMoneyBackBills, outerspace, overExposed, overExposedMekanism, parisianBuilding, pavia, pavs, pendulum, phantomKey, puurrtyCats, raisonDetreCryo,
    saltySeagullsSociety, sealSociety, smoothYetiMtnClub, smoothYetiSnowmobileParts, spacePugsAlpha, spaceBudz, uglyBros, uglyBrosXmas, uglyBrosDefinitive, uglyBrosValentine, unsignedAlgorithms, voyagePlanets, vyFI, woodLordsEarlyBird, yummiUniverseNaru, yummiUniverseSnoopy, jellyCubes, wildTangz];

let count = 0;

for (let x of cnftArray) {
    x.projectID = `project${count}`
    count++
}

export default cnftArray;